export const NAVBAR = 'navbar';
export const SIDEBAR_CONTENT = 'sidebarContent';
export const TOPBAR = 'topbar';
export const STUDIO = 'studio';
export const SANDBOX = 'sandbox';
export const USER_PROFILE = 'userProfile';
export const STUDIO_PROJECTS_CARD = 'PROJECT_CARD';
export const STUDIO_FOLDER = 'STUDIO_FOLDER';
export const BRAND_STORAGE = 'brandStorage';

export const userPermissions = {
  scenePage: 'SCENE_PAGE',
  sceneTool: 'SCENE_TOOL',
  studioPage: 'STUDIO_PAGE',
  studioTool: 'STUDIO_TOOL',
  sandboxPage: 'SANDBOX_PAGE',
  sandboxTool: 'SANDBOX_TOOL',
  brandRoom: 'BRAND_ROOM',
  noProjectsLimits: 'NO_PROJECT_LIMIT',
  allExportOptions: 'ALL_EXPORT_OPTIONS',
  randomizeTemplate: 'RANDOMIZE_TEMPLATE',
  backgroundRemoval: 'BACKGROUND_REMOVAL',
  isReplaceableFeatureEnabled: 'SCENE_REPLACEABLE',
  hasInternalAPIAccess: 'INTERNAL_API',
  hasStudioRegen: 'STUDIO_AI_RE_GEN',
  hasStudioFormatExtender: 'STUDIO_FORMAT_EXTENDER_TAB',
  hasStudioBrandRoom: 'STUDIO_BRAND_ROOM_TAB',
  hasStudioGenerativeBrief: 'STUDIO_GENERATIVE_BRIEF_TAB',
  hasStudioLayers: 'STUDIO_LAYERS_TAB',
  hasStudioPromptToDesign: 'STUDIO_P2D_TAB',
  extendedP2D: 'AI_PROMPT_TO_DESIGN_DEV',
  formatExtender: 'FORMAT_EXTENDER',
  assetsUpload: 'ASSETS_UPLOAD',
};

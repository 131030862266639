import React from 'react';
import cn from 'classnames';

// types
import { IGeneralProjectsContextMenu } from '../../../types/studio.types';

const GeneralProjectsContextMenu = ({
  position,
  isVisible,
  handleAddNewFolder,
  onCreateBlankProject,
  isToolStudio,
}: IGeneralProjectsContextMenu) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div
      style={{
        top: position.y,
        left: position.x,
      }}
      className={cn('context-menu', {
        light: !isToolStudio,
      })}
    >
      <div
        className="context-menu--option"
        id="general-context-menu-rename"
        onClick={() => onCreateBlankProject()}
      >
        <img src="/static/img/icons/white-plus.svg" alt="plus" />
        <span>New SCENE project</span>
      </div>

      <div
        className="context-menu--option"
        id="general-context-menu-duplicate"
        onClick={() => handleAddNewFolder(true)}
      >
        <img src="/static/img/icons/create-new-folder.svg" alt="duplicate" />
        <span>New Folder</span>
      </div>
    </div>
  );
};

export default GeneralProjectsContextMenu;

import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { BRAND_STORAGE } from '../../helpers/constants';

import { State } from './types';

const name = BRAND_STORAGE;
const initialState: State = {
  used: 0,
  total: 0,
};

const setBrandStorage: CaseReducer<State, PayloadAction<State>> = (
  state,
  { payload },
) => {
  return Object.assign(state, payload);
};

const reducers = {
  setBrandStorage,
};

export default createSlice({ name, initialState, reducers });

import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { Tab, TabList } from 'react-tabs';

// components
import ProPlanModal from '../Projects/ProPlanModal';

// icons
import ProjectsFolderIcon from '../../icons/projects/ProjectsFolderIcon';
import ProjectsP2DIcon from '../../icons/projects/ProjectsP2DIcon';
import ProjectsFormatExtenderIcon from '../../icons/projects/ProjectsFormatExtenderIcon';
import ProjectsBrandRoomIcon from '../../icons/projects/ProjectsBrandRoomIcon';
import ProjectsHelpTooltipIcon from '../../icons/projects/ProjectsHelpTooltipIcon';
import ProjectBrandRoomCloudIcon from '../../icons/projects/ProjectsBrandRoomCloudIcon';

// redux
import { useAppSelector } from '../../helpers/hooks';

// helpers
import { userPermissions } from '../../helpers/constants';
import { returnTabKey } from '../../helpers/projectsTab';

// hooks
import useClickOutside from '../../hooks/useClickOutside';

// types
import { IProjectsHeader } from '../../types/studio.types';

const languageOptions = [
  {
    id: 0,
    value: 'en',
    label: 'English',
  },
  {
    id: 1,
    value: 'es',
    label: 'Español',
  },
  {
    id: 2,
    value: 'pt-br',
    label: 'Português Brasileiro',
  },
];

interface IStorageBar {
  currentSpace: number;
  totalSpace: number;
  isDarkMode: boolean;
  // eslint-disable-next-line react/require-default-props
  additionalClass?: string;
}

const StorageBar = ({
  currentSpace,
  totalSpace,
  isDarkMode,
  additionalClass,
}: IStorageBar) => {
  return (
    <div
      className={cn(
        'projects-tab-content--header--profile-section--storage',
        additionalClass,
      )}
    >
      <div className="projects-tab-content--header--profile-section--storage--info">
        <div className="flex gap-2">
          <ProjectBrandRoomCloudIcon fill={isDarkMode ? '#eee' : '#646464'} />
          <p className="projects-tab-content--header--profile-section--storage--info--text dark:!text-[#eee]">
            Free storage
          </p>
        </div>
        <p className="projects-tab-content--header--profile-section--storage--info--text  dark:!text-[#eee]">
          {currentSpace}GB/{totalSpace}GB
        </p>
      </div>
      <div className="projects-tab-content--header--profile-section--storage--bar dark:!bg-[#fff]">
        <div
          className="projects-tab-content--header--profile-section--storage--bar--filled dark:!bg-[#323232]"
          style={{ width: `${(currentSpace * 100) / totalSpace}%` }}
        />
      </div>
    </div>
  );
};

const ProjectsHeader = ({
  searchParams,
  setSearchParams,
  renderFilters,
  isDarkMode,
  toggleDarkMode,
  title,
  additionalTitleClass,
  isNewDesignBtnVisible = true,
  isBrandStorage = false,
}: IProjectsHeader) => {
  const [isProfileDropdownShown, setIsProfileDropdownShown] = useState(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [isLanguageDropdownShown, setIsLanguageDropdownShown] = useState(false);
  const [isProPlanModalOpen, setIsProPlanModalOpen] = useState(false);

  const userProfile = useAppSelector((state) => state.userProfile);
  const { used: currentSpace, total: totalSpace } = useAppSelector(
    (state) => state.brandStorage,
  );

  const hasAssetsUploadPermission = userProfile.permissions.includes(
    userPermissions.assetsUpload,
  );

  const hasFormatExtenderPermission = userProfile.permissions.includes(
    userPermissions.formatExtender,
  );

  const profileDropdownRef = useRef(null);
  const languageDropdownRef = useRef(null);

  const onProfileDropdownOpen = () => {
    setIsProfileDropdownShown(true);
  };

  const onProfileDropdownClose = () => {
    setIsProfileDropdownShown(false);
  };

  const onOpenDrawer = () => {
    setIsDrawerOpened(true);
    document.body.classList.add('overflow-hidden');
  };

  const onCloseDrawer = () => {
    setIsDrawerOpened(false);
    document.body.classList.remove('overflow-hidden');
  };

  const onOpenLanguageDropdown = () => {
    setIsLanguageDropdownShown(true);
    if (isDrawerOpened) {
      setIsDrawerOpened(false);
    }
  };

  const onCloseLanguageDropdown = () => {
    setIsLanguageDropdownShown(false);
  };

  const onLanguageSelect = (val: string) => {
    searchParams.set('language', val);
    setSearchParams(searchParams);
  };

  const onProPlanModalOpen = () => {
    setIsProPlanModalOpen(true);
  };

  const onProPlanModalClose = () => {
    setIsProPlanModalOpen(false);
  };

  useClickOutside(profileDropdownRef, onProfileDropdownClose);
  useClickOutside(languageDropdownRef, onCloseLanguageDropdown);

  let userImage = '';

  if (
    userProfile.profilePicture.includes('/static/img/icons/account.svg') &&
    isDarkMode
  ) {
    userImage = '/static/img/icons/account-white.svg';
  } else {
    userImage = userProfile.profilePicture;
  }

  return (
    <>
      <div className="projects-tab-content--header">
        <div className="projects-tab-content--header--sort-section">
          <p
            className={cn(
              'projects-tab-content--header--sort-section--heading dark:!text-[color:#eeeeee]',
              additionalTitleClass,
            )}
          >
            {title}
          </p>
          <a
            href="/"
            className="projects-tab-content--header--sort-section--logo-container"
          >
            <img
              src={`/static/img/text-logo${isDarkMode ? '-light' : ''}.svg`}
              alt="logo"
              className="projects-tab-content--header--sort-section--logo-container--logo"
            />
          </a>
          {renderFilters && renderFilters('filters-container-desktop')}
        </div>
        <div className="projects-tab-content--header--profile-section">
          {isNewDesignBtnVisible && (
            <a
              href="/tool/scene"
              className="projects-tab-content--header--profile-section--new-design-btn dark:!bg-[#fffb00] dark:!text-[color:#212121]"
            >
              new design
            </a>
          )}
          {isBrandStorage && (
            <StorageBar
              isDarkMode={isDarkMode}
              currentSpace={currentSpace}
              totalSpace={totalSpace}
            />
          )}
          <div className="projects-tab-content--header--profile-section--language-container">
            <div
              className="projects-tab-content--header--profile-section--language-container--toggler"
              id="language-toggler"
              onClick={onOpenLanguageDropdown}
            >
              <img
                src={`/static/img/icons/globe${isDarkMode ? '-white' : ''}.svg`}
                alt="globe"
              />
            </div>
            {isLanguageDropdownShown && (
              <div
                className="projects-tab-content--header--profile-section--language-container--list font-fam-courier-prime dark:!bg-[#3b3b3b]"
                ref={languageDropdownRef}
              >
                {languageOptions.map((languageOption) => {
                  return (
                    <p
                      className={cn(
                        'projects-tab-content--header--profile-section--language-container--list--option',
                        {
                          active: searchParams.get('language')
                            ? languageOption.value ===
                              searchParams.get('language')
                            : languageOption.value === 'en',
                          dark: isDarkMode,
                          'dark-active':
                            (searchParams.get('language')
                              ? languageOption.value ===
                                searchParams.get('language')
                              : languageOption.value === 'en') && isDarkMode,
                        },
                      )}
                      key={languageOption.id}
                      onClick={() => onLanguageSelect(languageOption.value)}
                    >
                      {languageOption.label}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
          <div
            className="projects-tab-content--header--profile-section--burger-menu"
            onClick={onOpenDrawer}
          >
            <img
              src={`/static/img/icons/burger${isDarkMode ? '-white' : ''}.svg`}
              alt="burger"
              className="projects-tab-content--header--profile-section--burger-menu--icon"
            />
          </div>
          {isDrawerOpened && (
            <div className="drawer">
              <div
                className={cn('drawer--mask', {
                  active: isDrawerOpened,
                })}
                onClick={onCloseDrawer}
              />
              <div
                className={cn('drawer--content dark:!bg-[#3b3b3b]', {
                  active: isDrawerOpened,
                })}
              >
                <div>
                  <div>
                    <img
                      src={`/static/img/icons/arrow-back${
                        isDarkMode ? '-white' : ''
                      }.svg`}
                      alt="arrow-back"
                      onClick={onCloseDrawer}
                    />
                  </div>
                  <div className="drawer--content--profile-container">
                    <img
                      src={userImage}
                      alt="profile"
                      className="drawer--content--profile-container--img"
                    />
                    <div className="drawer--content--profile-container--info">
                      <p className="drawer--content--profile-container--info--name dark:!text-[color:#eeeeee]">
                        {userProfile.firstName} {userProfile.lastName}
                      </p>
                      <p className="drawer--content--profile-container--info--email">
                        {userProfile.email}
                      </p>
                    </div>
                    {isBrandStorage && (
                      <StorageBar
                        isDarkMode={isDarkMode}
                        currentSpace={currentSpace}
                        totalSpace={totalSpace}
                        additionalClass="mobile"
                      />
                    )}
                  </div>

                  <TabList className="drawer--content--links-container">
                    <Tab className="drawer--content--links-container--link">
                      <ProjectsFolderIcon
                        fill={isDarkMode ? '#eeeeee' : '#484848'}
                      />
                      <span
                        className={cn(
                          'drawer--content--links-container--link--text',
                          {
                            active:
                              returnTabKey(searchParams.get('tab')) === '0' ||
                              !searchParams.get('tab'),
                            dark: isDarkMode,
                          },
                        )}
                      >
                        Projects
                      </span>
                    </Tab>
                    <Tab className="drawer--content--links-container--link">
                      <ProjectsP2DIcon
                        fill={isDarkMode ? '#eeeeee' : '#484848'}
                      />
                      <span
                        className={cn(
                          'drawer--content--links-container--link--text',
                          {
                            active:
                              returnTabKey(searchParams.get('tab')) === '1',
                            dark: isDarkMode,
                          },
                        )}
                      >
                        Prompt-to-Design
                      </span>
                    </Tab>
                    <Tab
                      className="drawer--content--links-container--link"
                      disabled={!hasFormatExtenderPermission}
                    >
                      <ProjectsFormatExtenderIcon
                        fill={isDarkMode ? '#eeeeee' : '#484848'}
                      />
                      <span
                        className={cn(
                          'drawer--content--links-container--link--text',
                          {
                            active:
                              returnTabKey(searchParams.get('tab')) === '2',
                            dark: isDarkMode,
                          },
                        )}
                      >
                        Format Extender
                      </span>
                    </Tab>
                    <Tab className="drawer--content--links-container--link">
                      <ProjectsBrandRoomIcon
                        fill={isDarkMode ? '#eeeeee' : '#484848'}
                      />
                      <span
                        className={cn(
                          'drawer--content--links-container--link--text',
                          {
                            active:
                              returnTabKey(searchParams.get('tab')) === '3',
                            dark: isDarkMode,
                          },
                        )}
                      >
                        Brand Setup
                      </span>
                    </Tab>
                    <Tab className="drawer--content--links-container--link">
                      <ProjectsBrandRoomIcon
                        fill={isDarkMode ? '#eeeeee' : '#484848'}
                      />
                      <span
                        className={cn(
                          'drawer--content--links-container--link--text',
                          {
                            active:
                              returnTabKey(searchParams.get('tab')) === '4',
                            dark: isDarkMode,
                          },
                        )}
                      >
                        Brand Templates
                      </span>
                    </Tab>
                    <Tab
                      className="drawer--content--links-container--link"
                      disabled={!hasAssetsUploadPermission}
                    >
                      <ProjectsBrandRoomIcon
                        fill={isDarkMode ? '#eeeeee' : '#484848'}
                      />
                      <span
                        className={cn(
                          'drawer--content--links-container--link--text',
                          {
                            active:
                              returnTabKey(searchParams.get('tab')) === '5',
                            dark: isDarkMode,
                          },
                        )}
                      >
                        Brand Assets Library
                      </span>
                    </Tab>
                  </TabList>
                  <div className="drawer--content--divider">
                    <hr />
                  </div>
                  <a
                    className="drawer--content--links-container--link"
                    href="/account-settings/"
                  >
                    <img
                      src={`/static/img/icons/settings${
                        isDarkMode ? '-white' : '-dark'
                      }.svg`}
                      alt="folder"
                      className="drawer--content--links-container--link--icon !w-[16px] !h-[16px]"
                    />
                    <span className="drawer--content--links-container--link--text !ml-[7px] dark:!text-[color:#eeeeee]">
                      Go to Account Settings
                    </span>
                  </a>
                  <div
                    className="drawer--content--links-container--link flex justify-between"
                    onClick={toggleDarkMode}
                  >
                    <div className="flex gap-2">
                      <img
                        src={`/static/img/icons/settings${
                          isDarkMode ? '-white' : '-dark'
                        }.svg`}
                        alt="exit"
                        className="drawer--content--links-container--link--icon !w-[16px] !h-[16px]"
                      />
                      <p className="drawer--content--links-container--link--text !ml-[7px] dark:!text-[color:#eeeeee]">
                        Dark Mode
                      </p>
                    </div>
                    <label className="switch">
                      <input type="checkbox" checked={isDarkMode} readOnly />
                      <span className="slider round" />
                    </label>
                  </div>
                  <a
                    className="drawer--content--links-container--link"
                    href="/log-out/"
                  >
                    <img
                      src={`/static/img/icons/exit${
                        isDarkMode ? '-white' : '-dark'
                      }.svg`}
                      alt="exit"
                      className="drawer--content--links-container--link--icon !w-[16px] !h-[16px]"
                    />
                    <span className="drawer--content--links-container--link--text !ml-[7px] dark:!text-[color:#eeeeee]">
                      Sign out
                    </span>
                  </a>
                </div>

                <div>
                  <div className="drawer--content--upgrade-container dark:!bg-[#212121]">
                    <div className="drawer--content--upgrade-container--info">
                      <img
                        src="/static/img/icons/projects-yellow-like.svg"
                        alt="like"
                      />
                      <p className="drawer--content--upgrade-container--info--text dark:!text-[color:#eeeeee]">
                        free plan
                      </p>
                      <ProPlanModal
                        isOpen={isProPlanModalOpen}
                        onClose={onProPlanModalClose}
                      />
                    </div>
                    <p
                      className="drawer--content--upgrade-container--link"
                      onClick={onProPlanModalOpen}
                    >
                      upgrade
                    </p>
                  </div>

                  <a href="/help/faq">
                    <div className="drawer--content--help-section">
                      <ProjectsHelpTooltipIcon
                        fill={isDarkMode ? '#eeeeee' : '#484848'}
                      />
                      <span className="drawer--content--help-section--text dark:!text-[color:#eeeeee]">
                        Help & Feedback
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="projects-tab-content--header--profile-section--dropdown-container">
            {/* dynamic - isAuthenticated ? 1 : 2 */}
            <img
              src={userImage}
              alt="profile"
              className="projects-tab-content--header--profile-section--dropdown-container--img"
              onClick={onProfileDropdownOpen}
            />
            {isProfileDropdownShown && (
              <div
                className="projects-tab-content--header--profile-section--dropdown-container--dropdown dark:!bg-[#3b3b3b]"
                ref={profileDropdownRef}
              >
                <div className="projects-tab-content--header--profile-section--dropdown-container--dropdown--info-cntr">
                  <img
                    src={userImage}
                    alt="profile"
                    className="projects-tab-content--header--profile-section--dropdown-container--dropdown--info-cntr--img"
                  />
                  <div className="projects-tab-content--header--profile-section--dropdown-container--dropdown--info-cntr--user">
                    <p className="projects-tab-content--header--profile-section--dropdown-container--dropdown--info-cntr--user--name dark:!text-[color:#eeeeee]">
                      {userProfile.firstName} {userProfile.lastName}
                    </p>
                    <p className="projects-tab-content--header--profile-section--dropdown-container--dropdown--info-cntr--user--email">
                      {userProfile.email}
                    </p>
                  </div>
                </div>
                <span
                  className={cn(
                    'projects-tab-content--header--profile-section--dropdown-container--dropdown--option font-mono dark-mode-toggler',
                    {
                      dark: isDarkMode,
                    },
                  )}
                  onClick={toggleDarkMode}
                >
                  <div className="flex gap-2">
                    <img
                      src={`/static/img/icons/settings${
                        isDarkMode ? '-white' : '-dark'
                      }.svg`}
                      alt="exit"
                      className="projects-tab-content--header--profile-section--dropdown-container--dropdown--option--icon"
                    />
                    <p className="font-mono">Dark Mode</p>
                  </div>
                  <label className="switch">
                    <input type="checkbox" checked={isDarkMode} readOnly />
                    <span className="slider round" />
                  </label>
                </span>
                <a
                  className={cn(
                    'projects-tab-content--header--profile-section--dropdown-container--dropdown--option font-mono',
                    {
                      dark: isDarkMode,
                    },
                  )}
                  href="/log-out/"
                >
                  <img
                    src={`/static/img/icons/exit${isDarkMode ? '-white' : ''}${
                      !isDarkMode ? '-updated' : ''
                    }.svg`}
                    alt="exit"
                    className="projects-tab-content--header--profile-section--dropdown-container--dropdown--option--icon"
                  />
                  Sign out
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={cn('mobile-info-container', {
          dark: isDarkMode,
          shown: title !== 'Projects',
        })}
      >
        <p className="projects-tab-content--header--sort-section--heading-mobile dark:!text-[#eeeeee]">
          {title}
        </p>
      </div>
    </>
  );
};

export default ProjectsHeader;

import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { USER_PROFILE } from '../../helpers/constants';

import { UserProfileType } from './types';

const name = USER_PROFILE;
const initialState: UserProfileType = {
  isStaff: false,
  isSuperuser: false,
  isActive: false,
  permissions: [],
  profilePicture: '',
  firstName: '',
  lastName: '',
  email: '',
  projectsCount: 0,
  type: '',
};

const setProfile: CaseReducer<
  UserProfileType,
  PayloadAction<UserProfileType>
> = (state, { payload }) => {
  return Object.assign(state, payload);
};

const reducers = {
  setProfile,
};

export default createSlice({ name, initialState, reducers });

import fetch from '../helpers/customFetch';

export const getBrandRoom = () => {
  try {
    return fetch({
      url: `/brand-room/`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const updateBrandRoom = (data) => {
  try {
    return fetch({
      url: `/brand-room/`,
      method: 'PATCH',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

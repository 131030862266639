import { ProjectsSidebarTabKeysEnum } from '../types/common.types';

const projectsSidebarTab = {
  '0': 'projects',
  '1': 'promptToDesign',
  '2': 'formatExtender',
  '3': 'brandRoomSetup',
  '4': 'brandRoomTemplates',
  '5': 'brandRoomAssets',
};

export const returnTabName = (key: ProjectsSidebarTabKeysEnum) =>
  projectsSidebarTab[key];

export const returnTabKey = (name: string | null) =>
  Object.keys(projectsSidebarTab).find(
    (key) =>
      projectsSidebarTab[key as keyof typeof projectsSidebarTab] === name,
  );

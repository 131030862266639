import React from 'react';

const ProjectBrandRoomCloudIcon = ({ fill = '#646464' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.80951 17.2459C6.75653 17.2459 5.85837 16.8801 5.11501 16.1485C4.37167 15.4169 4 14.5246 4 13.4716C4 12.5397 4.29841 11.7109 4.89524 10.9851C5.49207 10.2594 6.25348 9.8301 7.17949 9.69726C7.4237 8.5583 7.99635 7.62691 8.89744 6.9031C9.79853 6.17929 10.8327 5.81738 12 5.81738C13.3783 5.81738 14.5482 6.29822 15.5099 7.25988C16.4715 8.22153 16.9524 9.39149 16.9524 10.7698V11.1507H17.1868C17.9897 11.2132 18.6593 11.5361 19.1956 12.1192C19.7319 12.7024 20 13.3954 20 14.1983C20 15.0481 19.7045 15.7685 19.1136 16.3595C18.5226 16.9505 17.8022 17.2459 16.9524 17.2459H7.80951ZM7.80951 16.1031H16.9524C17.4857 16.1031 17.9365 15.919 18.3048 15.5507C18.673 15.1825 18.8572 14.7317 18.8572 14.1983C18.8572 13.665 18.673 13.2142 18.3048 12.846C17.9365 12.4777 17.4857 12.2936 16.9524 12.2936H15.8095V10.7698C15.8095 9.71579 15.4381 8.81737 14.6952 8.07451C13.9524 7.33165 13.054 6.96022 12 6.96022C10.946 6.96022 10.0476 7.33165 9.30476 8.07451C8.5619 8.81737 8.19047 9.71579 8.19047 10.7698H7.80951C7.073 10.7698 6.44443 11.0301 5.9238 11.5507C5.40316 12.0713 5.14284 12.6999 5.14284 13.4364C5.14284 14.1729 5.40316 14.8015 5.9238 15.3221C6.44443 15.8428 7.073 16.1031 7.80951 16.1031Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProjectBrandRoomCloudIcon;

import React, { useState } from 'react';
import Modal from 'react-modal';

// icons
import CheckmarkCircle from '../../icons/CheckmarkCircle';

// styling
import '../../styles/projects/index.scss';

interface ProPlanModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ProPlanModal = ({ isOpen, onClose }: ProPlanModalProps) => {
  const [isImageVisible, setIsImageVisible] = useState(false);

  return (
    <>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={onClose}
        className="Modal__Content pro-plan-modal"
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        <div className="pro-plan-modal--container">
          <div className="pro-plan-modal--container--info">
            <p className="pro-plan-modal--container--info--title">
              Upgrade to Pro
            </p>
            <p className="pro-plan-modal--container--info--text">
              Imagine having all the tools you love at your fingertips—without
              limits and go beyond the basics with our Pro plan. Unlock advanced
              features, unlimited usage, and limitless potential to experience
              the platform’s full power.
            </p>
            <p className="pro-plan-modal--container--info--subtitle">
              Here is what’s included:
            </p>
            <div className="pro-plan-modal--container--info--features-list">
              <p className="pro-plan-modal--container--info--features-list--item">
                <span className="pro-plan-modal--container--info--features-list--item--icon">
                  <CheckmarkCircle />
                </span>{' '}
                <span className="pro-plan-modal--container--info--features-list--item--text">
                  Unlimited{' '}
                  <span className="pro-plan-modal--container--info--features-list--item--text bold">
                    Prompt-to-Design
                  </span>{' '}
                  so you can imagine up anything
                </span>
              </p>
              <p className="pro-plan-modal--container--info--features-list--item">
                <span className="pro-plan-modal--container--info--features-list--item--icon">
                  <CheckmarkCircle />
                </span>{' '}
                <span className="pro-plan-modal--container--info--features-list--item--text">
                  Access to{' '}
                  <span className="pro-plan-modal--container--info--features-list--item--text bold">
                    Brand Room
                  </span>{' '}
                  to create designs on-brand
                </span>
              </p>
              <p className="pro-plan-modal--container--info--features-list--item">
                <span className="pro-plan-modal--container--info--features-list--item--icon">
                  <CheckmarkCircle />
                </span>{' '}
                <span className="pro-plan-modal--container--info--features-list--item--text">
                  Generate many designs at once with{' '}
                  <span className="pro-plan-modal--container--info--features-list--item--text bold">
                    Bulk Designer
                  </span>{' '}
                </span>
              </p>
              <p className="pro-plan-modal--container--info--features-list--item">
                <span className="pro-plan-modal--container--info--features-list--item--icon">
                  <CheckmarkCircle />
                </span>{' '}
                <span className="pro-plan-modal--container--info--features-list--item--text">
                  One design for all platforms using{' '}
                  <span className="pro-plan-modal--container--info--features-list--item--text bold">
                    Format Extender
                  </span>{' '}
                </span>
              </p>
              <p className="pro-plan-modal--container--info--features-list--item">
                <span className="pro-plan-modal--container--info--features-list--item--icon">
                  <CheckmarkCircle />
                </span>{' '}
                <span className="pro-plan-modal--container--info--features-list--item--text">
                  High-resolution Exporting in all formats and sizes
                </span>
              </p>
            </div>
            <p className="pro-plan-modal--container--info--btn">
              Upgrade to PRO
            </p>
            <p className="pro-plan-modal--container--info--tip">
              You can cancel at any time.
            </p>
          </div>
          <div className="pro-plan-modal--container--banner">
            <img
              src="/static/img/pro-plan-banner.png"
              alt="banner"
              className="pro-plan-modal--container--banner--image"
              onLoad={() => setTimeout(() => setIsImageVisible(true), 200)}
              style={{ opacity: isImageVisible ? 1 : 0 }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProPlanModal;

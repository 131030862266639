import React, { useState } from 'react';

// components
import ProjectsHeader from '../shared/ProjectsHeader';
import PromptToDesignContent from '../shared/PromptToDesignContent';

// studio
import { IProjectsTabContent } from '../../types/studio.types';

// styles
import '../../styles/projects/projects-prompt-to-design-tab.scss';

const ProjectsPromptToDesignTab = ({
  searchParams,
  setSearchParams,
  isDarkMode,
  toggleDarkMode,
}: IProjectsTabContent) => {
  const [p2dStage, setP2dStage] = useState(1);

  const onSetP2dStage = (stage: number) => {
    setP2dStage(stage);
  };

  return (
    <div className="projects-tab-content">
      <ProjectsHeader
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        title="Prompt-to-Design"
        additionalTitleClass="p2d-title"
      />
      <div className="projects-tab-content--body">
        <div className="projects-p2d-container">
          <p className="projects-p2d-container--title dark:!text-[color:#eeeeee]">
            WHAT kind of social post are you making?
          </p>
          <div className="projects-p2d-container--formats-container">
            <p className="projects-p2d-container--formats-container--hint dark:!text-[color:#eeeeee]">
              in any format for
            </p>
            <div className="projects-p2d-container--formats-container--formats">
              <img
                src="../../../../static/img/icons/facebook-original-logo.svg"
                alt="facebook"
              />
              <img
                src={`../../../../static/img/icons/x${
                  isDarkMode ? '' : '-dark'
                }(ex-twitter).svg`}
                alt="twitter"
              />
              <img
                src="../../../../static/img/icons/linkedin-original-logo.svg"
                alt="linkedin"
              />
              <img
                src="../../../../static/img/icons/tiktok-original-logo.svg"
                alt="tiktok"
              />
              <img
                src="../../../../static/img/icons/youtube-original-logo.svg"
                alt="youtube"
              />
              <img
                src="../../../../static/img/icons/snapchat-original-logo.svg"
                alt="youtube"
              />
              <img
                src="../../../../static/img/icons/pinterest-original-logo.svg"
                alt="pinterest"
              />
              <img
                src="../../../../static/img/icons/twitch-original-logo.svg"
                alt="twitch"
              />
              <img
                src="../../../../static/img/icons/instagram-original-logo.svg"
                alt="instagram"
              />
            </div>
          </div>
          <PromptToDesignContent
            p2dStage={p2dStage}
            onSetP2dStage={onSetP2dStage}
            isDarkMode={isDarkMode}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectsPromptToDesignTab;

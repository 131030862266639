import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

// components
import ProjectsHeader from './ProjectsHeader';

// redux
import { actions as brandStorageActions } from '../../ducks/brandStorage';

// helpers
import { getToasterOptions } from '../../helpers/toaster';
import { useAppDispatch } from '../../helpers/hooks';
import { retrieveErrorFromServer } from '../../helpers/retrieveErrorFromServer';

// api
import { getBrandRoom, updateBrandRoom } from '../../api/brand-room';
import { getCustomFonts, uploadCustomFont } from '../../api/assets';
import { getBrandStorageInfo } from '../../api/helpers';

// types
import {
  BrandRoomType,
  IProjectsTabContent,
  UploadedFontType,
} from '../../types/studio.types';

// styles
import '../../styles/projects/projects-brand-room.scss';
import '../../styles/elements/toaster.scss';

interface IBrandNameForm {
  brandName: string;
}

interface IBrandDescriptionForm {
  brandDescription: string;
}

const ProjectsBrandRoomSetupTab = ({
  searchParams,
  setSearchParams,
  isDarkMode,
  toggleDarkMode,
}: IProjectsTabContent) => {
  const [isBrandRoomSetupModalOpen, setIsBrandRoomSetupModalOpen] =
    useState(false);
  const [isBrandRoomTextStylesModalOpen, setIsBrandRoomTextStylesModalOpen] =
    useState(false);
  const [isBrandRoomLogoModalOpen, setIsBrandRoomLogoModalOpen] =
    useState(false);
  const [isBrandRoomDescriptionModalOpen, setIsBrandRoomDescriptionModalOpen] =
    useState(false);
  const [isDeleteFontModalVisible, setIsDeleteFontModalVisible] =
    useState(false);
  const [brandRoomInfo, setBrandRoomInfo] = useState<null | BrandRoomType>();
  const [fonts, setFonts] = useState<UploadedFontType[] | null>(null);
  const [fontFilterValue, setFontFilterValue] = useState('');
  const [selectedFonts, setSelectedFonts] = useState<UploadedFontType[] | null>(
    null,
  );
  const [isFontModalOpen, setIsFontModalOpen] = useState(false);
  const [selectedFontToDelete, setSelectedFontToDelete] =
    useState<UploadedFontType | null>(null);
  const [shouldTriggerBrandStorage, setShouldTriggerBrandStorage] =
    useState(true);

  const dispatch = useAppDispatch();

  // brand-name form
  const {
    register: firstFormRegister,
    setValue: firstFormSetValue,
    handleSubmit: firstFormHandleSubmit,
  } = useForm<IBrandNameForm>();

  // brand-description form
  const {
    register: secondFormRegister,
    setValue: secondFormSetValue,
    handleSubmit: secondFormHandleSubmit,
  } = useForm<IBrandDescriptionForm>();

  const openBrandRoomSetupModal = () => {
    setIsBrandRoomSetupModalOpen(true);
  };

  const closeBrandRoomSetupModal = () => {
    setIsBrandRoomSetupModalOpen(false);
  };

  const openBrandRoomTextStylesModal = () => {
    setIsBrandRoomTextStylesModalOpen(true);
  };

  const closeBrandRoomTextStylesModal = () => {
    setIsBrandRoomTextStylesModalOpen(false);
  };

  const openBrandRoomLogoModal = () => {
    setIsBrandRoomLogoModalOpen(true);
  };

  const closeBrandRoomLogoModal = () => {
    setIsBrandRoomLogoModalOpen(false);
  };

  const openBrandRoomDescriptionModal = () => {
    setIsBrandRoomDescriptionModalOpen(true);
  };

  const closeBrandRoomDescriptionModal = () => {
    setIsBrandRoomDescriptionModalOpen(false);
  };

  const openDeleteFontModal = (innerSelectedFont: UploadedFontType) => {
    setSelectedFontToDelete(innerSelectedFont);

    setIsDeleteFontModalVisible(true);
  };

  const closeDeleteFontModal = () => {
    setIsDeleteFontModalVisible(false);
  };

  const openCustomFontModal = () => {
    setIsFontModalOpen(true);
  };

  const closeCustomFontModal = () => {
    setIsFontModalOpen(false);
  };

  const onFontFilterValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFontFilterValue(e.target.value);
  };

  const handleSubmitBrandNameForm = (data: IBrandNameForm) => {
    const formData = new FormData();

    formData.append('brand_name', data.brandName);

    updateBrandRoom(formData)
      .then((resp) => {
        closeBrandRoomSetupModal();
        setBrandRoomInfo(resp);
        toast.success('Data saved', getToasterOptions());
        setShouldTriggerBrandStorage(true);
      })
      .catch(() => {
        toast.error('Could not process data', getToasterOptions());
      });
  };

  const handleSubmitBrandDescriptionForm = (data: IBrandDescriptionForm) => {
    const formData = new FormData();

    formData.append('info', data.brandDescription);

    updateBrandRoom(formData)
      .then((resp) => {
        closeBrandRoomDescriptionModal();
        setBrandRoomInfo(resp);
        toast.success('Data saved', getToasterOptions());
        setShouldTriggerBrandStorage(true);
      })
      .catch(() => {
        toast.error('Could not process data', getToasterOptions());
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onImageUpload = (file: any) => {
    const formData = new FormData();

    formData.append('logo', file[0]);

    const toastId = toast.loading('Uploading logo');

    updateBrandRoom(formData)
      .then((resp) => {
        setBrandRoomInfo(resp);
        toast.update(toastId, {
          render: 'Logo uploaded successfully',
          type: 'success',
          isLoading: false,
          autoClose: 4000,
          hideProgressBar: true,
        });
        setShouldTriggerBrandStorage(true);
      })
      .catch((err) => {
        const errMsg =
          retrieveErrorFromServer(err) || 'Oooops, something went wrong';

        toast.update(toastId, {
          render: errMsg,
          type: 'error',
          isLoading: false,
          autoClose: 4000,
          hideProgressBar: true,
        });
      });
  };

  const onImageDelete = () => {
    const formData = new FormData();

    formData.append('logo', '');

    updateBrandRoom(formData)
      .then((resp) => {
        setBrandRoomInfo(resp);
        toast.success('Data saved', getToasterOptions());
        setShouldTriggerBrandStorage(true);
      })
      .catch(() => {
        toast.error('Could not process data', getToasterOptions());
      });
  };

  const onFontSelect = (font: UploadedFontType) => {
    setSelectedFonts((prevState) => {
      const isFontAlreadySelected = prevState
        ?.map((innerSelectedFont) => innerSelectedFont.slug)
        .includes(font.slug);

      if (!prevState?.length) {
        return [font];
      }

      return prevState && !isFontAlreadySelected
        ? [font, ...prevState]
        : prevState;
    });
  };

  const onRemoveFontFromFavorites = () => {
    setSelectedFonts((prevState) => {
      const filteredSelectedFonts = prevState?.filter(
        (innerSelectedFont) =>
          innerSelectedFont.slug !==
          (selectedFontToDelete as UploadedFontType).slug,
      );

      return filteredSelectedFonts?.length ? filteredSelectedFonts : null;
    });
    closeDeleteFontModal();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFontUpload = (file: any) => {
    const formData = new FormData();

    formData.append('font_file', file[0]);

    toast.info('Uploading font');

    uploadCustomFont(formData)
      .then((resp) => {
        setSelectedFonts((prevState) => {
          return prevState ? [resp, ...prevState] : [resp];
        });
        closeCustomFontModal();
        toast.success('Data saved', getToasterOptions());
      })
      .catch(() => {
        toast.error('Could not process data', getToasterOptions());
      });
  };

  const onSubmitBrandRoomFonts = () => {
    const formData = new FormData();

    if (selectedFonts?.length) {
      selectedFonts?.forEach((innerSelectedFont) => {
        formData.append('title_text_fonts', innerSelectedFont.slug);
      });
    } else {
      formData.append('clear_title_text_fonts', 'true');
    }

    updateBrandRoom(formData)
      .then((resp) => {
        setBrandRoomInfo(resp);
        toast.success('Data saved', getToasterOptions());
        setShouldTriggerBrandStorage(true);
        closeBrandRoomTextStylesModal();
      })
      .catch(() => {
        toast.error('Could not process data', getToasterOptions());
      });
  };

  useEffect(() => {
    getBrandRoom().then((res) => {
      setBrandRoomInfo(res);
      firstFormSetValue('brandName', res.brandName);
      secondFormSetValue('brandDescription', res.info);
      setSelectedFonts(res.titleTextFonts);
    });

    getCustomFonts().then((resp) => {
      setFonts(resp);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldTriggerBrandStorage) {
      getBrandStorageInfo().then((resp) => {
        dispatch(brandStorageActions.setBrandStorage(resp));
      });

      setShouldTriggerBrandStorage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTriggerBrandStorage]);

  const filteredFonts = fonts?.filter((font) =>
    font.fontFamily
      .toLocaleLowerCase()
      .includes(fontFilterValue.toLocaleLowerCase()),
  );

  return (
    <div className="projects-tab-content">
      <ProjectsHeader
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        title="Brand Room"
        isNewDesignBtnVisible={false}
        isBrandStorage
      />
      <div className="projects-tab-content--body">
        <div className="brand-room-container">
          <div className="brand-room-container--card-container">
            <div
              className="brand-room-container--card-container--field-card dark:!bg-[#000] dark:!text-[#eee] dark:!border-[#212121] dark:hover:!bg-[#191919]"
              id="context-menu-brand-name"
              onClick={openBrandRoomSetupModal}
            >
              brand name
              {brandRoomInfo?.brandName && (
                <img
                  src={`/static/img/icons/small-check-circle${
                    isDarkMode ? '-white' : ''
                  }.svg`}
                  alt="checkmark"
                />
              )}
            </div>
            <Modal
              isOpen={isBrandRoomSetupModalOpen}
              ariaHideApp={false}
              onRequestClose={() => {
                firstFormSetValue('brandName', brandRoomInfo?.brandName || '');
                closeBrandRoomSetupModal();
              }}
              className={`Modal__Content brand-room-modal dark:!bg-[#000] ${
                isDarkMode ? 'dark' : ''
              }`}
              parentSelector={() =>
                document.querySelector('#root') as HTMLElement
              }
            >
              <div className="brand-room-modal--content">
                <form
                  action="submit"
                  onSubmit={firstFormHandleSubmit(handleSubmitBrandNameForm)}
                  className="brand-room-modal--content form"
                >
                  <p className="brand-room-modal--content--title dark:!text-[#eeeeee]">
                    Choose a brand name
                  </p>
                  <input
                    type="text"
                    className="brand-room-modal--content--input dark:!bg-[#2d2d2d] dark:!border-[#212121]"
                    placeholder="Enter your brand name"
                    maxLength={60}
                    {...firstFormRegister('brandName')}
                  />
                  <div className="brand-room-modal--content--btns-container">
                    <button
                      className="brand-room-modal--content--btns-container--cancel dark:!text-[#fff]"
                      type="button"
                      onClick={() => {
                        firstFormSetValue(
                          'brandName',
                          brandRoomInfo?.brandName || '',
                        );
                        closeBrandRoomSetupModal();
                      }}
                    >
                      cancel
                    </button>
                    <button
                      className={cn(
                        'brand-room-modal--content--btns-container--submit',
                        {
                          'dark-submit': isDarkMode,
                        },
                      )}
                      type="submit"
                    >
                      submit
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
          </div>

          <div className="brand-room-container--card-container">
            <div
              className="brand-room-container--card-container--field-card dark:!bg-[#000] dark:!text-[#eee] dark:!border-[#212121] dark:hover:!bg-[#191919]"
              id="context-menu-brand-name"
              onClick={openBrandRoomTextStylesModal}
            >
              text styles
              {!!brandRoomInfo?.titleTextFonts.length && (
                <img
                  src={`/static/img/icons/small-check-circle${
                    isDarkMode ? '-white' : ''
                  }.svg`}
                  alt="checkmark"
                />
              )}
            </div>
            <Modal
              isOpen={isBrandRoomTextStylesModalOpen}
              ariaHideApp={false}
              onRequestClose={() => {
                setSelectedFonts(
                  brandRoomInfo?.titleTextFonts as UploadedFontType[],
                );
                closeBrandRoomTextStylesModal();
              }}
              className={`Modal__Content brand-room-modal dark:!bg-[#000] ${
                isDarkMode ? 'dark' : ''
              }`}
              parentSelector={() =>
                document.querySelector('#root') as HTMLElement
              }
            >
              <div className="brand-room-modal--content">
                <p className="brand-room-modal--content--title dark:!text-[#eeeeee]">
                  Choose a title text
                </p>
                <div className="brand-room-modal--content--fonts-container">
                  <div className="w-1/2 flex flex-col xs:w-full">
                    <div className="relative">
                      <input
                        type="text"
                        value={fontFilterValue}
                        onChange={onFontFilterValueChange}
                        className="w-full p-2 font-fam-open-sans text-sm leading-[24px] outline-none border-[1px] border-solid border-[#BCBCBC] pl-[40px] rounded-[4px_4px_0_0]
                        placeholder:text-[#888888] placeholder:font-fam-courier-prime placeholder:text-sm placeholder:leading-[24px]
                        dark:border-[#888888] dark:outline-none dark:placeholder:text-[color:#888888] dark:focus:border-[#EEEEEE] dark:focus:bg-[color:#2D2D2D]
                        dark:focus:text-[color:#ffffff] dark:focus:placeholder:text-[color:#ffffff] dark:hover:bg-[color:#363636] dark:bg-[color:#2D2D2D] dark:text-[#eeeeee]
                        "
                        placeholder="Search"
                      />
                      <img
                        src={`/static/img/icons/search-${
                          isDarkMode ? 'light' : 'dark'
                        }.svg`}
                        alt="search"
                        className="absolute top-[10px] left-[10px]"
                      />
                    </div>
                    <div className="border-[1px] border-solid border-[#BCBCBC] border-t-0 rounded-[0_0_4px_4px] px-[24px] max-h-[300px] overflow-auto xs:max-h-[150px]">
                      {filteredFonts?.length ? (
                        filteredFonts.map((font) => {
                          return (
                            <p
                              className={cn(
                                'brand-room-modal--content--fonts-container--font',
                                {
                                  dark: isDarkMode,
                                },
                              )}
                              key={font.slug}
                              onClick={() => onFontSelect(font)}
                            >
                              {font.isCustom && (
                                <img
                                  src={`/static/img/icons/account${
                                    isDarkMode ? '-white' : ''
                                  }.svg`}
                                  alt="account"
                                  className={cn(
                                    'brand-room-modal--content--fonts-container--font--img',
                                    {
                                      dark: isDarkMode,
                                    },
                                  )}
                                />
                              )}
                              {font.fontFamily}
                            </p>
                          );
                        })
                      ) : (
                        <p className="brand-room-modal--content--fonts-container--no-templates">
                          No Fonts
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="w-1/2 flex flex-col xs:w-full">
                    <p className="brand-room-modal--content--fonts-container--title dark:!text-[#eee]">
                      Your fonts
                    </p>
                    <div className="max-h-[300px] overflow-auto xs:max-h-[150px]">
                      {selectedFonts?.map((innerSelectedFont) => {
                        return (
                          <div
                            className="flex justify-between items-center"
                            key={uuidv4()}
                          >
                            <p
                              className={cn(
                                'brand-room-modal--content--fonts-container--font',
                                {
                                  dark: isDarkMode,
                                },
                              )}
                            >
                              {innerSelectedFont.isCustom && (
                                <img
                                  src={`/static/img/icons/account${
                                    isDarkMode ? '-white' : ''
                                  }.svg`}
                                  alt="account"
                                  className={cn(
                                    'brand-room-modal--content--fonts-container--font--img',
                                    {
                                      dark: isDarkMode,
                                    },
                                  )}
                                />
                              )}
                              {innerSelectedFont.fontFamily}
                            </p>
                            <img
                              src="/static/img/icons/cross.svg"
                              alt="cross"
                              className="cursor-pointer"
                              onClick={() =>
                                openDeleteFontModal(innerSelectedFont)
                              }
                            />
                          </div>
                        );
                      })}
                      <Modal
                        isOpen={isDeleteFontModalVisible}
                        ariaHideApp={false}
                        onRequestClose={closeDeleteFontModal}
                        className={`Modal__Content brand-room-modal dark:!bg-[#000] ${
                          isDarkMode ? 'dark' : ''
                        }`}
                        parentSelector={() =>
                          document.querySelector('#root') as HTMLElement
                        }
                      >
                        <div className="brand-room-modal--content">
                          <p className="brand-room-modal--content--title dark:!text-[#eeeeee]">
                            delete this font?
                          </p>

                          <p className="brand-room-modal--content--hint dark:!text-[#eeeeee]">
                            Are you sure you want to delete this font?
                          </p>

                          <div className="brand-room-modal--content--btns-container">
                            <div className="flex gap-2">
                              <button
                                className="brand-room-modal--content--btns-container--cancel dark:!text-[#fff]"
                                type="button"
                                onClick={closeDeleteFontModal}
                              >
                                cancel
                              </button>
                              <button
                                className={cn(
                                  'brand-room-modal--content--btns-container--submit',
                                  {
                                    'dark-submit': isDarkMode,
                                  },
                                )}
                                type="button"
                                onClick={onRemoveFontFromFavorites}
                              >
                                delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
                <div className="brand-room-modal--content--btns-container !justify-between xs:flex-col">
                  <button
                    className="brand-room-modal--content--btns-container--cancel dark:!text-[#fff] xs:w-full xs:mb-4"
                    type="button"
                    onClick={openCustomFontModal}
                  >
                    upload
                  </button>
                  <Modal
                    isOpen={isFontModalOpen}
                    ariaHideApp={false}
                    onRequestClose={closeCustomFontModal}
                    className={`Modal__Content brand-room-modal dark:!bg-[#000] ${
                      isDarkMode ? 'dark' : ''
                    }`}
                    parentSelector={() =>
                      document.querySelector('#root') as HTMLElement
                    }
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        const filteredFiles = acceptedFiles.filter(
                          (file) =>
                            file.name.toLowerCase().endsWith('.ttf') ||
                            file.name.toLowerCase().endsWith('.otf'),
                        );
                        if (filteredFiles.length === 0) {
                          alert('Only .ttf and .otf files are allowed.');
                          return;
                        }
                        onFontUpload(filteredFiles);
                      }}
                      accept={{
                        'font/ttf': ['.ttf'],
                        'font/otf': ['.otf'],
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className="brand-room-modal--content--image-dropzone"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} type="file" />
                          <div className="flex flex-col">
                            <button
                              type="button"
                              className="brand-room-modal--content--image-dropzone--btn"
                            >
                              choose a file or drag it here
                            </button>
                            <p className="brand-room-modal--content--image-dropzone--hint">
                              Accepts .ttf, .otf
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </Modal>
                  <div className="flex gap-4 xs:flex-col">
                    <button
                      className="brand-room-modal--content--btns-container--cancel dark:!text-[#fff] xs:w-full"
                      type="button"
                      onClick={() => {
                        setSelectedFonts(
                          brandRoomInfo?.titleTextFonts as UploadedFontType[],
                        );
                        closeBrandRoomTextStylesModal();
                      }}
                    >
                      cancel
                    </button>
                    <button
                      className={cn(
                        'brand-room-modal--content--btns-container--submit xs:w-full',
                        {
                          'dark-submit': isDarkMode,
                        },
                      )}
                      type="button"
                      onClick={onSubmitBrandRoomFonts}
                    >
                      submit
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>

          <div className="brand-room-container--card-container">
            <div
              className="brand-room-container--card-container--field-card dark:!bg-[#000] dark:!text-[#eee] dark:!border-[#212121] dark:hover:!bg-[#191919]"
              id="context-menu-brand-name"
              onClick={openBrandRoomLogoModal}
            >
              logo
              {brandRoomInfo?.logo && (
                <img
                  src={`/static/img/icons/small-check-circle${
                    isDarkMode ? '-white' : ''
                  }.svg`}
                  alt="checkmark"
                />
              )}
            </div>
            <Modal
              isOpen={isBrandRoomLogoModalOpen}
              ariaHideApp={false}
              onRequestClose={closeBrandRoomLogoModal}
              className={`Modal__Content brand-room-modal dark:!bg-[#000] ${
                isDarkMode ? 'dark' : ''
              }`}
              parentSelector={() =>
                document.querySelector('#root') as HTMLElement
              }
            >
              <div className="brand-room-modal--content">
                <p className="brand-room-modal--content--title dark:!text-[#eeeeee]">
                  upload brand logo
                </p>
                {brandRoomInfo?.logo ? (
                  <img
                    src={brandRoomInfo?.logo}
                    alt="logo"
                    className="brand-room-modal--content--logo"
                  />
                ) : (
                  <Dropzone
                    onDrop={(acceptedFiles) => onImageUpload(acceptedFiles)}
                  >
                    {({
                      getRootProps: getUploadReplacementRootProps,
                      getInputProps: getUploadReplacementInputProps,
                    }) => (
                      <div
                        className="brand-room-modal--content--image-dropzone"
                        {...getUploadReplacementRootProps()}
                      >
                        <input {...getUploadReplacementInputProps()} />
                        <div className="flex flex-col">
                          <button
                            type="button"
                            className="brand-room-modal--content--image-dropzone--btn"
                          >
                            choose a file or drag it here
                          </button>
                          <p className="brand-room-modal--content--image-dropzone--hint">
                            Accepts .gif, .jpg, .png and .webp
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                )}
                <div className="brand-room-modal--content--btns-container">
                  {!brandRoomInfo?.logo && (
                    <button
                      className="brand-room-modal--content--btns-container--outlined-btn"
                      type="button"
                      onClick={closeBrandRoomLogoModal}
                    >
                      go back
                    </button>
                  )}

                  {brandRoomInfo?.logo && (
                    <div className="flex gap-2">
                      <button
                        className="brand-room-modal--content--btns-container--outlined-btn"
                        type="button"
                        onClick={onImageDelete}
                      >
                        delete
                      </button>
                      <Dropzone
                        onDrop={(acceptedFiles) => onImageUpload(acceptedFiles)}
                      >
                        {({
                          getRootProps: getUploadReplacementRootProps,
                          getInputProps: getUploadReplacementInputProps,
                        }) => (
                          <button
                            className="brand-room-modal--content--btns-container--outlined-btn"
                            type="button"
                            {...getUploadReplacementRootProps()}
                          >
                            <input {...getUploadReplacementInputProps()} />
                            replace
                          </button>
                        )}
                      </Dropzone>
                    </div>
                  )}
                </div>
              </div>
            </Modal>
          </div>

          <div className="brand-room-container--card-container">
            <div
              className="brand-room-container--card-container--field-card dark:!bg-[#000] dark:!text-[#eee] dark:!border-[#212121] dark:hover:!bg-[#191919]"
              id="context-menu-brand-name"
              onClick={openBrandRoomDescriptionModal}
            >
              brand description
              {brandRoomInfo?.info && (
                <img
                  src={`/static/img/icons/small-check-circle${
                    isDarkMode ? '-white' : ''
                  }.svg`}
                  alt="checkmark"
                />
              )}
            </div>
            <Modal
              isOpen={isBrandRoomDescriptionModalOpen}
              ariaHideApp={false}
              onRequestClose={() => {
                closeBrandRoomDescriptionModal();
                secondFormSetValue(
                  'brandDescription',
                  brandRoomInfo?.info || '',
                );
              }}
              className={`Modal__Content brand-room-modal dark:!bg-[#000] ${
                isDarkMode ? 'dark' : ''
              }`}
              parentSelector={() =>
                document.querySelector('#root') as HTMLElement
              }
            >
              <div className="brand-room-modal--content">
                <form
                  action="submit"
                  onSubmit={secondFormHandleSubmit(
                    handleSubmitBrandDescriptionForm,
                  )}
                  className="brand-room-modal--content form"
                >
                  <p className="brand-room-modal--content--title dark:!text-[#eeeeee]">
                    describe your brand
                  </p>
                  <textarea
                    cols={5}
                    rows={5}
                    className="brand-room-modal--content--textarea dark:!bg-[#2d2d2d] dark:!border-[#212121]"
                    maxLength={1000}
                    placeholder="What does your brand do? What are its values? "
                    {...secondFormRegister('brandDescription')}
                  />
                  <div className="brand-room-modal--content--btns-container">
                    <button
                      className="brand-room-modal--content--btns-container--cancel dark:!text-[#fff]"
                      type="button"
                      onClick={() => {
                        closeBrandRoomDescriptionModal();
                        secondFormSetValue(
                          'brandDescription',
                          brandRoomInfo?.info || '',
                        );
                      }}
                    >
                      cancel
                    </button>
                    <button
                      className={cn(
                        'brand-room-modal--content--btns-container--submit',
                        {
                          'dark-submit': isDarkMode,
                        },
                      )}
                      type="submit"
                    >
                      submit
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsBrandRoomSetupTab;

import React, { useState } from 'react';

// components
import PromptToDesignHeader from './PromptToDesignHeader';
import PromptToDesignContent from '../../shared/PromptToDesignContent';

// styling
import '../../../styles/studio/prompt-to-design.scss';

const PromptToDesign = () => {
  const [p2dStage, setP2dStage] = useState(1);

  const onSetP2dStage = (stage: number) => {
    setP2dStage(stage);
  };

  return (
    <div className="p2d-container">
      <PromptToDesignHeader p2dStage={p2dStage} />
      <PromptToDesignContent
        p2dStage={p2dStage}
        onSetP2dStage={onSetP2dStage}
      />
    </div>
  );
};

export default PromptToDesign;

import { configureStore, combineReducers } from '@reduxjs/toolkit';

import logger from 'redux-logger';

import {
  NAVBAR,
  SIDEBAR_CONTENT,
  TOPBAR,
  STUDIO,
  SANDBOX,
  USER_PROFILE,
  BRAND_STORAGE,
} from './helpers/constants';

/** Reducers */
import { reducer as navbarReducer } from './ducks/navbar';
import { reducer as sidebarContentReducer } from './ducks/sidebarContent';
import { reducer as topbarReducer } from './ducks/topbar';
import { reducer as studioReducer } from './ducks/scene';
import { reducer as sandboxReducer } from './ducks/3dScene';
import { reducer as profileReducer } from './ducks/profile';
import { reducer as brandStorageReducer } from './ducks/brandStorage';

const rootReducer = combineReducers({
  [NAVBAR]: navbarReducer,
  [SIDEBAR_CONTENT]: sidebarContentReducer,
  [TOPBAR]: topbarReducer,
  [STUDIO]: studioReducer,
  [SANDBOX]: sandboxReducer,
  [USER_PROFILE]: profileReducer,
  [BRAND_STORAGE]: brandStorageReducer,
});

const reducer = rootReducer;

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

export const { dispatch, getState } = store;
export type RootState = ReturnType<typeof store.getState>;

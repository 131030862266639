import fetch from '../helpers/customFetch';

export const generateAIText = (data) => {
  try {
    return fetch({
      url: `/helpers/ai/text-generation/`,
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const rephraseAIText = (data) => {
  try {
    return fetch({
      url: `/helpers/ai/text-rephrase/`,
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getAITextHistory = (data) => {
  try {
    return fetch({
      url: `/helpers/ai/text-generation/`,
      method: 'GET',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const generateFormatExtenderPageFormats = (data) => {
  try {
    return fetch({
      url: '/helpers/format-extender/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getGeneratedFormatExtenderPageFormats = () => {
  try {
    return fetch({
      url: '/helpers/format-extender/',
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const deleteGeneratedFormatExtenderPageFormats = (id) => {
  try {
    return fetch({
      url: `/helpers/format-extender/${id}/`,
      method: 'DELETE',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const saveGeneratedFormatExtenderPageFormats = (id) => {
  try {
    return fetch({
      url: `/helpers/format-extender/${id}/save/`,
      method: 'POST',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getReGen = () => {
  try {
    return fetch({
      url: '/helpers/ai/re-gen/',
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const createReGen = (data) => {
  try {
    return fetch({
      url: '/helpers/ai/re-gen/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const finishReGen = (data) => {
  try {
    return fetch({
      url: '/helpers/ai/re-gen/finish/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const createCustomDesignAssistance = (data) => {
  try {
    return fetch({
      url: '/helpers/ai/re-gen/design-assistance/custom/',
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const createMoreFormatsDesignAssistance = (data) => {
  try {
    return fetch({
      url: '/helpers/ai/re-gen/design-assistance/more-formats/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const createNewVariantsDesignAssistance = (data) => {
  try {
    return fetch({
      url: '/helpers/ai/re-gen/design-assistance/new-variants/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const submitPromptToDesignFeedback = (data) => {
  try {
    return fetch({
      url: '/helpers/ai/prompt-to-design/feedback/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const submitReGenFeedback = (data) => {
  try {
    return fetch({
      url: `/helpers/ai/re-gen/feedback/`,
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const editPromptToDesignData = (data, id) => {
  try {
    return fetch({
      url: `/helpers/ai/prompt-to-design/${id}/`,
      method: 'PATCH',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const reRollImages = async (id) => {
  try {
    return await fetch({
      url: `/helpers/ai/prompt-to-design/${id}/re_roll_images/`,
      method: 'POST',
    });
  } catch (err) {
    // eslint-disable-next-line no-undef
    toastr.error(err.response.data[0]);
  }
};

export const insertQRCode = (data) => {
  try {
    return fetch({
      url: '/helpers/add-qrcode-to-scene/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const createPromptToDesign = (data) => {
  try {
    return fetch({
      url: '/helpers/ai/prompt-to-design/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const generatePromptToDesign = (id, data = {}) => {
  try {
    return fetch({
      url: `/helpers/ai/prompt-to-design/${id}/generate/`,
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getPromptToDesign = (id) => {
  try {
    return fetch({
      url: `/helpers/ai/prompt-to-design/${id}/`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const createPromptToDesignRecommendations = (id, data) => {
  try {
    return fetch({
      url: `/helpers/ai/prompt-to-design/${id}/recommendations/`,
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getStudioPromptToDesignStatus = (id) => {
  try {
    return fetch({
      url: `/helpers/ai/prompt-to-design/${id}/`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

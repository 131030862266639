import React, { useState, useRef } from 'react';
import Select from 'react-select';
import cn from 'classnames';

// components
import ProjectsContent from '../shared/ProjectsContent';
import ProjectsHeader from '../shared/ProjectsHeader';

// helpers
import { debounce } from '../../helpers/helpers';

// hooks
import useClickOutside from '../../hooks/useClickOutside';

// types
import {
  DropdownStylesType,
  IProjectsSortOption,
} from '../../types/bottom-actions.types';
import { InputChangeEventType } from '../../types/common.types';
import { IProjectsTabContent } from '../../types/studio.types';

// styles
import '../../styles/projects/projects-tab-content.scss';

export const darkDropdownStyles: DropdownStylesType = {
  indicatorSeparator: () => ({ display: 'none' }),
  control: (baseStyles) => ({
    ...baseStyles,
    background: '#363636',
    border: '0.5px solid #474747',
    width: '100%',
    color: '#EEEEEE',
    ':hover': {
      border: '0.5px solid #242424',
    },
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    height: '40px',
    paddingLeft: '35px',

    ':before': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '10px',
      margin: 'auto',
      background: 'center no-repeat url(/static/img/icons/sort-icon-white.svg)',
      content: '" "',
      display: 'block',
      height: 16,
      width: 16,
    },

    ':after': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '35px',
      margin: 'auto',
      content: '" "',
      display: 'block',
      background: '#474747',
      height: 40,
      width: 1,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    textOverflow: 'ellipsis',
    width: '192px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'initial',
    color: '#EEEEEE',
  }),
  menu: () => ({
    background: '#363636',
    position: 'absolute',
    width: '100%',
    zIndex: 1000,
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#ffffff',
    };
  },
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    position: 'relative',
    backgroundColor: isSelected || isFocused ? '#EAECF0' : undefined,
    paddingRight: 32,
    margin: 6,
    borderRadius: 6,
    width: '100%',
    color: '#EEEEEE',
    background: '#363636',
    textOverflow: 'ellipsis',
    maxWidth: '97%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ':hover': {
      background: isSelected ? '#363636' : '#ffffff',
      color: isSelected ? '#EEEEEE' : '#000000',
      cursor: isSelected ? 'default' : 'pointer',
    },
  }),
};

const dropdownStyles: DropdownStylesType = {
  indicatorSeparator: () => ({ display: 'none' }),
  control: (baseStyles) => ({
    ...baseStyles,
    background: '#ffffff',
    border: '1px solid #BCBCBC',
    width: '100%',
    color: '#484848',
    ':hover': {
      border: '1px solid #BCBCBC',
    },
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    height: '40px',
    paddingLeft: '35px',

    ':before': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '10px',
      margin: 'auto',
      background: 'center no-repeat url(/static/img/icons/sort-icon.svg)',
      content: '" "',
      display: 'block',
      height: 16,
      width: 16,
    },

    ':after': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '35px',
      margin: 'auto',
      content: '" "',
      display: 'block',
      background: '#bcbcbc',
      height: 40,
      width: 1,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    textOverflow: 'ellipsis',
    width: '192px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'initial',
    color: '#484848',
  }),
  menu: () => ({
    background: '#ffffff',
    position: 'absolute',
    width: '100%',
    zIndex: 1000,
    border: '1px solid #BCBCBC',
    borderRadius: '8px',
    marginTop: '4px',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#ffffff',
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    position: 'relative',
    backgroundColor: isSelected || isFocused ? '#EAECF0' : undefined,
    color: isDisabled ? '#ccc' : 'black',
    paddingRight: 32,
    margin: 6,
    borderRadius: 6,
    width: 'calc(100% - 12px)',
    cursor: 'pointer',
  }),
};

const dropdownOptions = [
  {
    value: '-created_at',
    label: 'Most Recent',
  },
  {
    value: 'name',
    label: 'Sort By Name',
  },
  {
    value: 'created_at',
    label: 'By Date Created',
  },
];

const filterDropdown = [
  {
    id: 0,
    value: null,
    label: 'no filter applied',
  },
  {
    id: 1,
    value: 'IN_PROGRESS',
    label: 'filter by IN PROGRESS',
  },
  {
    id: 2,
    value: 'COMPLETED',
    label: 'filter by COMPLETE',
  },
  {
    id: 3,
    value: 'PUBLISHED',
    label: 'filter by PUBLISHED',
  },
];

const ProjectsTabContent = ({
  searchParams,
  setSearchParams,
  isDarkMode,
  toggleDarkMode,
}: IProjectsTabContent) => {
  const [isFilterDropdownOpened, setIsFilterDropdownOpened] = useState(false);
  const [currentProjectsPage, setCurrentProjectsPage] = useState(1);

  const setNextPage = () => {
    setCurrentProjectsPage((prevState) => prevState + 1);
  };

  const filterDropdownRef = useRef(null);

  const openFilterDropdown = () => {
    setIsFilterDropdownOpened(true);
  };

  const closeFilterDropdown = () => {
    setIsFilterDropdownOpened(false);
  };

  const onFilterOptionSelect = () => {
    document.querySelector('#scrollableDiv')?.scroll(0, 0);
    setCurrentProjectsPage(1);
  };

  function saveInput(e: InputChangeEventType) {
    onFilterOptionSelect();

    searchParams.set('search', e.target.value);
    setSearchParams(searchParams);
  }
  const onInput = debounce((e: InputChangeEventType) => saveInput(e));

  const onSortSelectChange = (val: IProjectsSortOption) => {
    onFilterOptionSelect();

    searchParams.set('sort', val.value);
    setSearchParams(searchParams);
  };

  const onFilterChange = (val: string | null) => {
    closeFilterDropdown();
    onFilterOptionSelect();

    if (val) {
      searchParams.set('filter', val);
    } else {
      searchParams.delete('filter');
    }
    setSearchParams(searchParams);
  };

  const renderFilters = (
    containerClassName = '',
    singleFilterContainerClassName = '',
  ) => {
    return (
      <div className={containerClassName}>
        <div className="projects-tab-content--header--sort-section--filter-select-container">
          <div
            className="projects-tab-content--header--sort-section--filter-select-container--toggler dark:!bg-[#363636] dark:!text-[color:#eeeeee] dark:!border-[#474747]"
            onClick={openFilterDropdown}
          >
            <img
              src={`/static/img/icons/projects-filter${
                searchParams.get('filter') ? '' : '-not'
              }-applied${isDarkMode ? '-light' : ''}.svg`}
              alt="checkmark"
            />
            <img
              src={`/static/img/icons/projects-filter${
                isDarkMode ? '-light' : ''
              }.svg`}
              alt="filter"
            />
          </div>
          {isFilterDropdownOpened && (
            <div
              ref={filterDropdownRef}
              className="projects-tab-content--header--sort-section--filter-select-container--dropdown dark:!bg-[#363636] dark:!border-[#474747]"
              id="filter-context-menu"
            >
              {filterDropdown.map((innerFilterOption) => {
                return (
                  <div
                    className={cn(
                      'projects-tab-content--header--sort-section--filter-select-container--dropdown--option dark:!text-[color:#eeeeee]',
                      {
                        active:
                          innerFilterOption.value ===
                          searchParams.get('filter'),
                        'dark-active':
                          innerFilterOption.value ===
                            searchParams.get('filter') && isDarkMode,
                        dark: isDarkMode,
                      },
                    )}
                    key={innerFilterOption.id}
                    onClick={() => onFilterChange(innerFilterOption.value)}
                  >
                    {innerFilterOption.label}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          className={cn(
            'projects-tab-content--header--sort-section--sort-select',
            singleFilterContainerClassName,
          )}
        >
          <Select
            placeholder="Choose option ..."
            styles={isDarkMode ? darkDropdownStyles : dropdownStyles}
            isSearchable={false}
            options={dropdownOptions}
            value={
              dropdownOptions.find(
                (innerOption) => innerOption.value === searchParams.get('sort'),
              ) || dropdownOptions[0]
            }
            onChange={(val) => {
              onSortSelectChange(val as IProjectsSortOption);
            }}
          />
        </div>
        <div
          className={cn(
            'projects-tab-content--header--sort-section--input-container',
            singleFilterContainerClassName,
          )}
        >
          <input
            type="text"
            className="projects-tab-content--header--sort-section--input-container--input dark:!bg-[#363636] dark:!text-[color:#eeeeee] dark:!border-[#474747]"
            placeholder="Search"
            onChange={onInput}
            defaultValue={searchParams.get('search') || ''}
          />
          <img
            src={`/static/img/icons/search${
              isDarkMode ? '-light' : '-dark'
            }.svg`}
            alt="search"
            className="projects-tab-content--header--sort-section--input-container--icon"
          />
        </div>
      </div>
    );
  };

  useClickOutside(
    filterDropdownRef,
    closeFilterDropdown,
    'projects-tab-content--header--sort-section--filter-select-container--dropdown',
  );

  return (
    <div className="projects-tab-content">
      <ProjectsHeader
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        renderFilters={renderFilters}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        title="Projects"
      />
      <div
        className={cn('mobile-info-container', {
          dark: isDarkMode,
          shown: true,
        })}
      >
        <p className="projects-tab-content--header--sort-section--heading-mobile dark:!text-[#eeeeee]">
          Projects
        </p>
        {renderFilters('filters-container-mobile', 'mobile-filter-container')}
      </div>
      <div className="projects-tab-content--body">
        <ProjectsContent
          allowSelectProjects
          isDarkMode={isDarkMode}
          setNextPage={setNextPage}
          currentProjectsPage={currentProjectsPage}
        />
      </div>
      <div className="projects-tab-content--bottom">
        <a
          href="/tool/scene"
          className="projects-tab-content--bottom--new-design-btn dark:!bg-[#fffb00] dark:!text-[color:#212121]"
        >
          new design
        </a>
      </div>
    </div>
  );
};

export default ProjectsTabContent;

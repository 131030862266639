import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

// redux
import { useAppSelector } from '../../helpers/hooks';

// api
import {
  createMultiplePromptToDesign,
  createPromptToDesign,
  createPromptToDesignRecommendations,
  generateMultiplePromptToDesign,
  getMultiplePromptToDesign,
  generatePromptToDesign,
  getPromptToDesign,
} from '../../api/helpers';
import { getPageFormats, getTemplatesGenres } from '../../api/assets';

// helpers
import {
  darkDropdownStyles,
  imageReplacementTypes,
  SUGGESTIONS_ARRAY,
} from '../../helpers/common.data';
import { convertImageUrlToFile } from '../../helpers/common.helpers';
import generateZip from '../../helpers/editor/generateZip';
import { userPermissions } from '../../helpers/constants';

// types
import {
  GeneratedP2DType,
  IPromptToDesignContent,
  RecommendationType,
  TemplateGenreType,
} from '../../types/studio.types';
import { PageFormat } from '../../types/studio-format-extender.types';
import { DropdownOptionType } from '../../types/common.types';
import { DropdownStylesType } from '../../types/bottom-actions.types';

// styling
import '../../styles/studio/prompt-to-design.scss';

const dropdownStyles = (
  controlStyling = {},
  singleValueStyling = {},
  menuStyling = {},
  placeholderStyling = {},
  optionStyling = {},
): DropdownStylesType => ({
  indicatorSeparator: () => ({ display: 'none' }),
  control: (baseStyles) => ({
    ...baseStyles,
    ...controlStyling,
    background: '#ffffff',
    border: '1px solid #BCBCBC',
    width: '100%',
    color: '#888',
    ':hover': {
      border: '1px solid #BCBCBC',
    },
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    height: '40px',
  }),
  singleValue: (provided) => ({
    ...provided,
    ...singleValueStyling,
    textOverflow: 'ellipsis',
    width: '192px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'initial',
    color: '#888',
  }),
  menu: () => ({
    ...menuStyling,
    background: '#ffffff',
    position: 'absolute',
    width: '100%',
    zIndex: 100000,
    border: '1px solid #BCBCBC',
    borderRadius: '8px',
    marginTop: '4px',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      ...placeholderStyling,
      color: '#888',
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    ...optionStyling,
    position: 'relative',
    backgroundColor: isSelected || isFocused ? '#fff' : undefined,
    color: isDisabled ? '#ccc' : '#888',
    paddingRight: 32,
    margin: 6,
    borderRadius: 6,
    width: 'calc(100% - 12px)',
    cursor: 'pointer',
    ':hover': {
      background: isSelected ? '#fff' : '#eee',
      cursor: isSelected ? 'default' : 'pointer',
    },

    ...(isSelected
      ? {
          ':after': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 10,
            margin: 'auto',
            background: 'center no-repeat url(/static/img/icons/tick.svg)',
            content: '" "',
            display: 'block',
            height: 14,
            width: 14,
          },
        }
      : {}),
  }),
});

interface IStudioPromptToDesignForm {
  purpose: string;
  imageReplacementType: string;
  templateGenre: number;
  templateFormat: number;
  useOriginalShape: boolean;
  // might be used later
  // isCaptions: boolean;
  designsN: number;
}

const RECOMMENDATIONS_PLACEHOLDERS = [
  {
    id: 0,
    preview: '',
  },
  {
    id: 1,
    preview: '',
  },
  {
    id: 2,
    preview: '',
  },
  {
    id: 3,
    preview: '',
  },
  {
    id: 4,
    preview: '',
  },
];

const DESIGN_AMOUNT_DROPDOWN_OPTIONS = [
  {
    id: 0,
    label: '1',
    value: 1,
  },
  {
    id: 1,
    label: '2',
    value: 2,
  },
  {
    id: 2,
    label: '3 (default)',
    value: 3,
  },
  {
    id: 3,
    label: '4',
    value: 4,
  },
  {
    id: 4,
    label: '5',
    value: 5,
  },
];

const PromptToDesignContent = ({
  p2dStage,
  onSetP2dStage,
  isDarkMode,
}: IPromptToDesignContent) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [recommendations, setRecommendations] = useState(
    RECOMMENDATIONS_PLACEHOLDERS,
  );
  const [selectedRecommendation, setSelectedRecommendation] =
    useState<null | RecommendationType>({
      id: 0,
      preview: '',
    });
  const [generatedP2dId, setGeneratedP2dId] = useState();
  const [p2dError, setP2dError] = useState('');
  const [p2dId, setP2dId] = useState<null | number>(null);
  const [pageFormats, setPageFormats] = useState<PageFormat[]>([]);
  const [templateGenres, setTemplateGenres] = useState<TemplateGenreType[]>([]);
  const [generatedDesigns, setGeneratedDesigns] = useState<GeneratedP2DType[]>(
    [],
  );
  const [generatedP2DFolder, setGeneratedP2DFolder] = useState<number>();

  const inputContainerRef = useRef(null);

  const navigate = useNavigate();

  const userProfile = useAppSelector((state) => state.userProfile);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
    getValues,
  } = useForm<IStudioPromptToDesignForm>({
    defaultValues: {
      designsN: DESIGN_AMOUNT_DROPDOWN_OPTIONS[2].value,
    },
  });

  const onInputFocus = () => {
    setIsInputFocused(true);
  };

  const onInputBlur = () => {
    setIsInputFocused(false);
  };

  const onP2dRandomize = () => {
    if (inputContainerRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (inputContainerRef.current as any).childNodes[0].focus();
    }
    setIsInputFocused(true);
    setValue(
      'purpose',
      SUGGESTIONS_ARRAY[Math.floor(Math.random() * 10)].message,
      {
        shouldValidate: true,
      },
    );
  };

  const redirectToScene = (
    userProject: number,
    id: number,
    isProjectOrigin = false,
  ) => {
    navigate({
      pathname: `/tool/scene/${userProject}/`,
      search: `?origin_type=p2d&p2dId=${id}${
        isProjectOrigin ? '' : '&redirectedFromStudio=1'
      }`,
    });
  };

  const onGeneratePromptToDesign = async (
    id: number,
    designsAmount?: number,
  ) => {
    let response = null;

    if (designsAmount && designsAmount > 1) {
      response = await getMultiplePromptToDesign(id);
    } else {
      response = await getPromptToDesign(id);
    }

    if (response.status == 'failed') {
      setP2dError(
        response.errorMessage ||
          'Encountered an error during Prompt-to-Design, please try again later.',
      );

      onSetP2dStage(1);
    } else if (
      response.status === 'in_progress' ||
      response.status === 'created'
    ) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await onGeneratePromptToDesign(id, designsAmount);
    } else if (response.status === 'finished') {
      if (typeof isDarkMode === 'boolean') {
        if (designsAmount && designsAmount === 1) {
          redirectToScene(response.userProjectId, response.id, true);
        } else {
          navigate({
            pathname: `/projects`,
            search: `?folder=${response.folder}`,
          });
        }
      } else {
        if (designsAmount && designsAmount === 1) {
          redirectToScene(response.userProjectId, response.id);
        }

        onSetP2dStage(3);
        setGeneratedDesigns(response.results);
        setGeneratedP2DFolder(response.folder);
      }
    }
  };

  const onCreatePromptToDesign = async (data: IStudioPromptToDesignForm) => {
    let createP2DResponse = null;
    onSetP2dStage(1.5);

    try {
      if (data.designsN > 1) {
        createP2DResponse = await createMultiplePromptToDesign({
          ...data,
          pageSource:
            typeof isDarkMode === 'boolean' ? '/generative-ai/' : 'studio',
        });
      } else {
        createP2DResponse = await createPromptToDesign({
          ...data,
          pageSource:
            typeof isDarkMode === 'boolean' ? '/generative-ai/' : 'studio',
        });
      }

      setP2dId(createP2DResponse.id);

      setGeneratedP2dId(createP2DResponse?.id || p2dId);
      if (typeof isDarkMode === 'boolean') {
        if (data.designsN > 1) {
          await generateMultiplePromptToDesign(createP2DResponse?.id || p2dId, {
            ...data,
          });
        } else {
          generatePromptToDesign(createP2DResponse?.id || p2dId, { ...data });
        }

        onGeneratePromptToDesign(createP2DResponse?.id || p2dId, data.designsN);
      } else {
        if (data.templateGenre) {
          // eslint-disable-next-line no-use-before-define
          onSelectRecommendation(null, createP2DResponse.id);

          return;
        }

        createPromptToDesignRecommendations(
          data.designsN > 1
            ? createP2DResponse?.results[0].id
            : createP2DResponse?.id,
          {
            purpose: data.purpose,
            quantity: data.designsN,
          },
        ).then((innerRecommendations) => {
          setRecommendations([]);
          setRecommendations(innerRecommendations.templates);
          onSetP2dStage(2);
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setP2dError(
        error?.response?.data?.detail ||
          'Encountered an error during Prompt-to-Design, please try again later.',
      );
    }
  };

  const onFormSubmit = (data: IStudioPromptToDesignForm) => {
    setRecommendations(
      RECOMMENDATIONS_PLACEHOLDERS.slice(
        0,
        typeof isDarkMode === 'boolean' ? 1 : data.designsN,
      ),
    );
    onCreatePromptToDesign(data);
  };

  const onSelectRecommendation = async (
    recommendation: RecommendationType | null,
    id?: number,
  ) => {
    onSetP2dStage(recommendation ? 2.5 : 1.5);
    recommendation && setSelectedRecommendation(recommendation);

    const formValues = getValues();

    try {
      const generatePromptToDesignData = {
        template: recommendation?.id,
        designsN: formValues.designsN,
      };

      if (formValues.designsN > 1) {
        await generateMultiplePromptToDesign(
          generatedP2dId || id,
          generatePromptToDesignData,
        );

        await getMultiplePromptToDesign(generatedP2dId || id);
      } else {
        await generatePromptToDesign(
          generatedP2dId || id,
          generatePromptToDesignData,
        );
        await getPromptToDesign(generatedP2dId || id);
      }

      onGeneratePromptToDesign(
        (generatedP2dId as unknown as number) || (id as number),
        formValues.designsN,
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setP2dError(
        error.response.data.detail ||
          'Encountered an error during Prompt-to-Design, please try again later.',
      );
    }
  };

  const handleRestart = () => {
    setP2dError('');
    onFormSubmit(getValues());
  };

  const redirectToProjects = () => {
    window.location.href = `/projects/?folder=${generatedP2DFolder}`;
  };

  const onDownloadAll = async () => {
    const projectsImages = generatedDesigns.map(async (project, index) => {
      const file = await convertImageUrlToFile(project.preview, index);

      return file;
    });

    const files = await Promise.all(projectsImages);

    generateZip(files);
  };

  useEffect(() => {
    getTemplatesGenres().then((innerTemplateGenres) => {
      setTemplateGenres(innerTemplateGenres);
    });
    getPageFormats().then((innerPageFormats) => {
      setPageFormats(innerPageFormats);
    });
  }, []);

  const templateGenresDropdownOptions = templateGenres.map(
    (innerTemplateGenre) => ({
      id: innerTemplateGenre.id,
      label: innerTemplateGenre.name,
      value: innerTemplateGenre.id,
    }),
  );
  const pageFormatsDropdownOptions = pageFormats.map((innerPageFormat) => ({
    id: innerPageFormat.id,
    label: innerPageFormat.name,
    value: innerPageFormat.id,
  }));

  // TODO:Ros - apply regex to match inputMatchP2dFormat. format - (Design a x for a y)
  const inputMatchP2dFormat = watch('purpose');
  const isP2DListDisabled = p2dStage === 1.5 || p2dStage === 2.5;
  const generateBtnTitle = p2dStage === 1 ? 'start' : 'loading...';
  const hasExtendedP2DPermission = userProfile.permissions.includes(
    userPermissions.extendedP2D,
  );

  return (
    <div
      className={cn('p2d-container--content', {
        'max-w-675': p2dStage !== 1,
      })}
    >
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className={cn('p2d-container--content form', {
          'max-w-675': p2dStage !== 1,
        })}
      >
        <div className="p2d-container--content input-control">
          <div
            className={cn('p2d-container--content--input-container', {
              hidden: p2dStage === 3,
            })}
          >
            <div
              className={cn(
                'p2d-container--content--input-container--randomize-btn',
                {
                  light: !isDarkMode && typeof isDarkMode === 'boolean',
                  disabled: isP2DListDisabled,
                },
              )}
              onClick={onP2dRandomize}
            >
              <img
                src={`../../../../static/img/icons/randomize-p2d${
                  !isDarkMode && typeof isDarkMode === 'boolean'
                    ? '-light-mode'
                    : ''
                }.svg`}
                alt="randomize"
              />
            </div>
            <div className="input-inner-container" ref={inputContainerRef}>
              <input
                placeholder="Write a prompt to create your design"
                className={cn(
                  'p2d-container--content--input-container--field',
                  {
                    'focused-light': isInputFocused && !isDarkMode,
                    focused: isInputFocused,
                    disabled: isP2DListDisabled,
                    light: !isDarkMode && typeof isDarkMode === 'boolean',
                  },
                )}
                {...register('purpose', {
                  required: true,
                  onChange: () => {
                    clearErrors();
                  },
                })}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                maxLength={150}
              />
            </div>
            <img
              src={`../../../../static/img/icons/enter-icon${
                isDarkMode ? '' : '-light-mode'
              }.svg`}
              alt="enter"
              className={cn(
                'p2d-container--content--input-container--field--icon',
                {
                  visible: isInputFocused,
                },
              )}
            />
          </div>
          {errors?.purpose && (
            <p className="p2d-container--content input-error-msg">
              Field is required
            </p>
          )}

          {hasExtendedP2DPermission && p2dStage === 1 && (
            <div className="p2d-container--content--additional-info-container">
              <Controller
                control={control}
                name="imageReplacementType"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Image Replacement Type"
                    styles={
                      !isDarkMode && typeof isDarkMode === 'boolean'
                        ? dropdownStyles(
                            {},
                            { fontFamily: 'Courier Prime' },
                            {},
                            { fontFamily: 'Courier Prime' },
                            { fontFamily: 'Courier Prime' },
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          )
                        : (darkDropdownStyles(
                            { height: '40px' },
                            { fontFamily: 'Courier Prime' },
                            {},
                            { fontFamily: 'Courier Prime' },
                            { fontFamily: 'Courier Prime' },
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          ) as any)
                    }
                    isSearchable={false}
                    maxMenuHeight={200}
                    options={imageReplacementTypes}
                    value={imageReplacementTypes.find((c) => c.value === value)}
                    onChange={(val) => onChange(val?.value)}
                  />
                )}
              />
              <Controller
                control={control}
                name="templateGenre"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Template Genre"
                    styles={
                      !isDarkMode && typeof isDarkMode === 'boolean'
                        ? dropdownStyles(
                            {},
                            { fontFamily: 'Courier Prime' },
                            {},
                            { fontFamily: 'Courier Prime' },
                            { fontFamily: 'Courier Prime' },
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          )
                        : (darkDropdownStyles(
                            { height: '40px' },
                            { fontFamily: 'Courier Prime' },
                            {},
                            { fontFamily: 'Courier Prime' },
                            { fontFamily: 'Courier Prime' },
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          ) as any)
                    }
                    isSearchable={false}
                    maxMenuHeight={200}
                    options={
                      templateGenresDropdownOptions as unknown as DropdownOptionType[]
                    }
                    value={
                      templateGenresDropdownOptions.find(
                        (c) => c.value === value,
                      ) as unknown as DropdownOptionType
                    }
                    onChange={(val) => onChange(val?.value)}
                  />
                )}
              />
              <Controller
                control={control}
                name="templateFormat"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Template Format"
                    styles={
                      !isDarkMode && typeof isDarkMode === 'boolean'
                        ? dropdownStyles(
                            {},
                            { fontFamily: 'Courier Prime' },
                            {},
                            { fontFamily: 'Courier Prime' },
                            { fontFamily: 'Courier Prime' },
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          )
                        : (darkDropdownStyles(
                            { height: '40px' },
                            { fontFamily: 'Courier Prime' },
                            {},
                            { fontFamily: 'Courier Prime' },
                            { fontFamily: 'Courier Prime' },
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          ) as any)
                    }
                    maxMenuHeight={200}
                    isSearchable={false}
                    options={
                      pageFormatsDropdownOptions as unknown as DropdownOptionType[]
                    }
                    value={
                      pageFormatsDropdownOptions.find(
                        (c) => c.value === value,
                      ) as unknown as DropdownOptionType
                    }
                    onChange={(val) => onChange(val?.value)}
                  />
                )}
              />

              <div className="p2d-container--content--additional-info-container--marks-container">
                <div className="p2d-container--content--additional-info-container--marks-container--element">
                  <span
                    className={cn(
                      'p2d-container--content--additional-info-container--marks-container--element--text',
                      {
                        light: !isDarkMode && typeof isDarkMode === 'boolean',
                      },
                    )}
                  >
                    original shape
                  </span>
                  <label className="checkbox-container !block right-[-10px] !left-auto !top-[3px]">
                    <input
                      type="checkbox"
                      {...register('useOriginalShape', {
                        onChange: () => {
                          clearErrors();
                        },
                      })}
                    />
                    <span
                      className={cn('checkmark', {
                        light: !isDarkMode && typeof isDarkMode === 'boolean',
                      })}
                    />
                  </label>
                </div>
                <div className="p2d-container--content--additional-info-container--marks-container--element">
                  <span
                    className={cn(
                      'p2d-container--content--additional-info-container--marks-container--element--text',
                      {
                        light: !isDarkMode && typeof isDarkMode === 'boolean',
                      },
                    )}
                  >
                    captions
                  </span>
                  <label className="checkbox-container !block right-[-10px] !left-auto !top-[3px]">
                    <input
                      type="checkbox"
                      // might be used later
                      // {...register('isCaptions', {
                      //   onChange: () => {
                      //     clearErrors();
                      //   },
                      // })}
                    />
                    <span
                      className={cn('checkmark', {
                        light: !isDarkMode && typeof isDarkMode === 'boolean',
                      })}
                    />
                  </label>
                </div>
              </div>
              <div className="p2d-container--content--additional-info-container--marks-container">
                <div className="p2d-container--content--additional-info-container--marks-container--element">
                  <span
                    className={cn(
                      'p2d-container--content--additional-info-container--marks-container--element--text',
                      {
                        light: !isDarkMode && typeof isDarkMode === 'boolean',
                      },
                    )}
                  >
                    how many designs to generate?
                  </span>
                </div>
                <div className="p2d-container--content--additional-info-container--marks-container--element">
                  <Controller
                    control={control}
                    name="designsN"
                    rules={{ required: hasExtendedP2DPermission }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        placeholder="Choose amount"
                        styles={
                          !isDarkMode && typeof isDarkMode === 'boolean'
                            ? dropdownStyles(
                                {},
                                { fontFamily: 'Courier Prime' },
                                {},
                                { fontFamily: 'Courier Prime' },
                                { fontFamily: 'Courier Prime' },
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              )
                            : (darkDropdownStyles(
                                { height: '40px' },
                                { fontFamily: 'Courier Prime' },
                                {},
                                { fontFamily: 'Courier Prime' },
                                { fontFamily: 'Courier Prime' },
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              ) as any)
                        }
                        maxMenuHeight={100}
                        isSearchable={false}
                        options={
                          DESIGN_AMOUNT_DROPDOWN_OPTIONS as unknown as DropdownOptionType[]
                        }
                        value={
                          DESIGN_AMOUNT_DROPDOWN_OPTIONS.find(
                            (c) => c.value === value,
                          ) as unknown as DropdownOptionType
                        }
                        onChange={(val) => onChange(val?.value)}
                        defaultValue={
                          DESIGN_AMOUNT_DROPDOWN_OPTIONS[2] as unknown as DropdownOptionType
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {p2dStage === 2.5 && selectedRecommendation && (
          <div className="p2d-container--content--single-recommendation">
            <div className="p2d-container--content--single-recommendation--item active animate-pulse">
              <div className="p2d-container--content--single-recommendation--item--image-container active">
                <img
                  src={selectedRecommendation.preview}
                  alt="recommendation"
                  className="p2d-container--content--single-recommendation--item--image-container--image active"
                />
              </div>
              <div className="p2d-container--content--single-recommendation--item--btn-container active">
                <button
                  type="button"
                  className="p2d-container--content--single-recommendation--item--btn-container--btn active"
                >
                  <img src="/static/img/icons/star-studio.svg" alt="star" />
                  loading ...
                </button>
              </div>
            </div>
          </div>
        )}

        {p2dStage !== 1 && p2dStage !== 2.5 && p2dStage !== 3 && (
          <div className="p2d-container--content--generation-container">
            {recommendations.length ? (
              recommendations.map((recommendation) => {
                return (
                  <div
                    className={cn(
                      'p2d-container--content--generation-container--item',
                      {
                        active: p2dStage === 2,
                      },
                    )}
                    key={recommendation.id}
                  >
                    <div
                      className={cn(
                        'p2d-container--content--generation-container--item--image-container',
                        {
                          skeleton: p2dStage !== 2,
                          'skeleton-dark':
                            p2dStage !== 2 &&
                            !isDarkMode &&
                            typeof isDarkMode === 'boolean',
                          active: p2dStage === 2,
                        },
                      )}
                    >
                      {recommendation.preview && (
                        <img
                          src={recommendation.preview}
                          alt="recommendation"
                          className={cn(
                            'p2d-container--content--generation-container--item--image-container--image',
                            {
                              active: p2dStage === 2,
                            },
                          )}
                        />
                      )}
                    </div>
                    <div
                      className={cn(
                        'p2d-container--content--generation-container--item--btn-container',
                        {
                          active: p2dStage === 2,
                        },
                      )}
                    >
                      <button
                        type="button"
                        className={cn(
                          'p2d-container--content--generation-container--item--btn-container--btn',
                          {
                            skeleton: p2dStage !== 2,
                            'skeleton-dark':
                              p2dStage !== 2 &&
                              !isDarkMode &&
                              typeof isDarkMode === 'boolean',
                            active: p2dStage === 2,
                          },
                        )}
                        onClick={() => onSelectRecommendation(recommendation)}
                      >
                        {p2dStage === 2 && (
                          <img
                            src="/static/img/icons/star-studio.svg"
                            alt="star"
                          />
                        )}
                        {p2dStage === 2 ? 'select' : ''}
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="p2d-container--no-recommendations">
                No recommendations were generated
              </p>
            )}
          </div>
        )}
        {p2dStage !== 2 && p2dStage !== 2.5 && p2dStage !== 3 && (
          <div className="p2d-container--content--actions-container">
            <button
              type="submit"
              className={cn(
                'p2d-container--content--actions-container--submit-btn',
                {
                  disabled: !inputMatchP2dFormat || p2dStage === 1.5,
                },
              )}
            >
              {generateBtnTitle}
            </button>
          </div>
        )}
        {p2dStage === 2 && (
          <div className="p2d-container--content--try-generative-design-container">
            {/* className="p2d-container--content--try-generative-design-container--left-container" */}
            <div>
              {/* <img
                src="../../../../static/img/icons/double-star.svg"
                alt="stars"
              />
              <p className="p2d-container--content--try-generative-design-container--left-container--title">
                want more options?
              </p>
              <img
                src="../../../../static/img/icons/double-star.svg"
                alt="stars"
              /> */}
              {!!recommendations.length && (
                <a
                  href="/pricing"
                  className="p2d-container--content--try-generative-design-container--left-container--btn"
                  target="_blank"
                >
                  try generative design
                </a>
              )}
            </div>
            <div className="p2d-container--content--try-generative-design-container--right-container">
              <button
                type="button"
                className="p2d-container--content--try-generative-design-container--right-container--btn"
                onClick={handleRestart}
              >
                <img
                  src="../../../../static/img/icons/reload.svg"
                  alt="reload"
                />
                <span>restart</span>
              </button>
            </div>
          </div>
        )}
        {p2dStage === 3 && (
          <>
            <div className="p2d-container--content--generation-container">
              {generatedDesigns.map((design) => {
                return (
                  <div
                    className="p2d-container--content--generation-container--item cursor-pointer"
                    key={design.id}
                    onClick={() =>
                      redirectToScene(design.userProject, design.id)
                    }
                  >
                    <div className="p2d-container--content--generation-container--item--image-container">
                      {design.preview && (
                        <img
                          src={design.preview}
                          alt="recommendation"
                          className="p2d-container--content--generation-container--item--image-container--image active"
                        />
                      )}
                      {design.caption && (
                        <div className="p2d-container--content--generation-container--item--image-container--placeholder">
                          {design.caption}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="p2d-container--content--generation-container--actions">
              <button
                type="button"
                className="p2d-container--content--generation-container--actions--download custom-btn"
                onClick={onDownloadAll}
              >
                download
              </button>
              <button
                type="button"
                className="p2d-container--content--generation-container--actions--projects custom-btn"
                onClick={redirectToProjects}
              >
                go to projects
              </button>
            </div>
            <button
              type="button"
              className="p2d-container--content--try-generative-design-container--right-container--btn !w-[200px]"
              onClick={handleRestart}
            >
              <img src="../../../../static/img/icons/reload.svg" alt="reload" />
              <span>restart</span>
            </button>
          </>
        )}
      </form>
      {p2dError && <p className="p2d-container--content--error">{p2dError}</p>}
    </div>
  );
};

export default PromptToDesignContent;
